var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "offer-post-step-intro" },
    [
      _vm.isDirectMode && _vm.artistData
        ? [
            _c("div", { staticClass: "opsi-directMode" }, [
              _c("div", {
                staticClass: "ui-bg-img opsi-img",
                style: {
                  backgroundImage:
                    "url(" +
                    _vm.$lib.cdnUrl(
                      _vm.artistData.teamImgUrl || _vm.artistData.imgUrl1
                    ) +
                    ")"
                }
              }),
              _c("div", { staticClass: "opsi-teamName" }, [
                _c("div", { staticClass: "opsi-teamName-row" }, [
                  _c("div", { staticClass: "opsi-teamName-col col-ellipsis" }, [
                    _vm._v(_vm._s(_vm.artistData.teamName))
                  ]),
                  _c("div", { staticClass: "opsi-teamName-col" }, [
                    _vm._v("님에게")
                  ])
                ]),
                _vm._m(0)
              ]),
              _c("div", { staticClass: "opsi-desc" }, [
                _vm._v(" 1:1 섭외 문의를 남기면"),
                _c("br"),
                _vm._v(
                  " " +
                    _vm._s(_vm.artistData.teamName) +
                    "님이 직접 견적을 보내드려요. "
                )
              ])
            ])
          ]
        : [
            _c("page-header", {
              attrs: {
                title: "정말 쉬운,\n엔터테이너/공연팀 섭외",
                subtitle:
                  "여기저기 알아 볼 필요 없어요.\n원하는 조건 등록하고\n실시간 견적을 받아보세요."
              }
            }),
            _c("icon", {
              style: {
                margin: "20px auto 0"
              },
              attrs: {
                src: "img/offer/post-offer-process.png",
                width: "422px",
                height: "109px"
              }
            })
          ],
      _c("footer-box", {
        attrs: {
          submitText: "다음",
          submitCb: function() {
            return _vm.nextStep()
          },
          submitDisabled: !_vm.validate
        }
      })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "opsi-teamName-row" }, [
      _c("div", { staticClass: "opsi-teamName-col" }, [
        _vm._v(" 견적 요청하기! ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }