<template>
  <div class="offer-post-step-intro">
    <template v-if="isDirectMode && artistData">
      <div class="opsi-directMode">
        <div
          class="ui-bg-img opsi-img"
          :style="{
            backgroundImage: `url(${$lib.cdnUrl(artistData.teamImgUrl || artistData.imgUrl1)})`
          }"
        />

        <div class="opsi-teamName">
          <div class="opsi-teamName-row">
            <div class="opsi-teamName-col col-ellipsis">{{ artistData.teamName }}</div>
            <div class="opsi-teamName-col">님에게</div>
          </div>
          <div class="opsi-teamName-row">
            <div class="opsi-teamName-col">
              견적 요청하기!
            </div>
          </div>
        </div>

        <div class="opsi-desc">
          1:1 섭외 문의를 남기면<br>
          {{ artistData.teamName }}님이 직접 견적을 보내드려요.
        </div>
      </div>
    </template>
    <template v-else>
      <page-header
        :title="`정말 쉬운,\n엔터테이너/공연팀 섭외`"
        :subtitle="`여기저기 알아 볼 필요 없어요.\n원하는 조건 등록하고\n실시간 견적을 받아보세요.`"
      />

      <icon
        src="img/offer/post-offer-process.png"
        width="422px"
        height="109px"
        :style="{
          margin: '20px auto 0',
        }"
      />
    </template>

    <footer-box
      submitText="다음"
      :submitCb="() => nextStep()"
      :submitDisabled="!validate"
    />
  </div>
</template>

<script>
import PageHeader from '@/components/common/PageHeader'
import FooterBox from '@/components/common/FooterBox'
import Icon from '@/components/common/Icon'

import { usePostOfferStore } from '@/pinia/modules/post-offer'

export default {
  components: {
    PageHeader,
    FooterBox,
    Icon,
  },
  data() {
    return {
    }
  },
  computed: {
    step: {
      get() {
        return this.$route.params.step
      },
    },
    postOffer: {
      get() {
        return this.$store.state.postOffer
      },
      set(val) {
        this.$store.commit('setPostOffer', val)
      },
    },
    validate: {
      get() {
        return true
      },
    },
    artistData() {
      return usePostOfferStore().artistData
    },
    isDirectMode() {
      return !!this.artistData && this.postOffer?.mode === 'direct'
    },
  },
  methods: {
    nextStep() {
      this.$parent.nextStep()
    },
  },
}
</script>

<style lang="scss" scoped>
.offer-post-step-intro {
  .opsi-directMode {
    .opsi-img {
      width: 16rem;
      height: 16rem;
      border-radius: 50%;
      display: block;
      margin: 70px auto 30px;
    }

    .opsi-teamName {
      font-size: 40px;
      font-weight: 600;
      margin-bottom: 42px;

      .opsi-teamName-row {
        display: flex;
        justify-content: center;
      }

      .opsi-teamName-col {
        min-width: fit-content;

        &.col-ellipsis {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          min-width: 0;
        }
      }
    }

    .opsi-desc {
      font-size: 22px;
      color: #979797;
      text-align: center;
    }
  }
}
</style>
